@import '../../styles/_colors';
@import '../../styles/_fonts';

$font-footer-title: $blogger;
$font-footer: $roboto;

$footer: $primary-color;
$footer-text: $black;
$footer-bottom-text: $black;
$footer-text-label: $purple;
$footer-text-title: $purple;
$footer-link: $black;
$footer-bottom-link: $black;
$footer-bottom-link-hover: $purple;

footer.page-footer {
  background-color: $footer;
}

.footer-text {
  color: $footer-text;
  font-family: $font-footer;
  columns: 2;
}

.footer-links > li {
  padding-bottom: 0.5rem;
}

.footer-bottom-text {
  color: $footer-bottom-text;
  font-family: $font-footer;
}

.footer-text-label {
  color: $footer-text-label;
  font-family: $font-footer;
  font-weight: 500;
}

.footer-title {
  color: $footer-text-title;
  font-family: $font-footer-title;
  font-size: 2.2rem;
  padding-top: 30px;
  margin-bottom: -10px;
}

.footer-link {
  color: $footer-link;
  font-family: $font-footer;
}

.footer-bottom-link {
  color: $footer-bottom-link;
  font-family: $font-footer;

  &:hover {
    color: $footer-bottom-link-hover;
  }
}

.page-footer {
  padding-top: 0px;
}


.footer-contact {
  line-height: 1;
}

.footer-contact > p {
  color: $black;
}

.footer-contact > p > a {
  color: $purple;
  font-weight: 500;
}

.footer-contact > p > svg {
  color: $white-grey;
}

.white-square-footer {
  border-radius: 0.6em;
  color: $purple;
  background-color: $white-grey;
  font-family: $product-sans;
  padding: 0.5em;
  font-weight: 600;
  font-size: 0.9rem;
  text-align: left;
}

.footer-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.footer-logo {
  align-self: center;
  padding: 20px;
}

.page-footer .footer-copyright {
  display: flex;
  justify-content: space-around;
}

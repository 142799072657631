$white: white;
$blue-grey: #607d8b;
$blue-grey-dark: #455a64;
$dark-grey: #424242;
$light-grey: #f1f1f1;
$grey: #e0e0e0;
$black: #000000;
//$blue: #3b5998;
$blue: #0277bd;
//$blue: #437AA3;
//$yellow: #f9a825;
$amber: #ffa000;
$brown: #795548;
$red: #ef5350;
//$red: #e53935;
$green: #81c784;
//$green: #4caf50;
//$green: #0f9d58;
//$purple: #8F5E72;
//$purple: #994d79;
$purple: #a3437a;
//$beige: #D0C3BD;
$beige: #d2c2bb;
$beige-light: #f0ebe9;
//$beige: #d5c1b8;
//$yellow-post-it: #f2ab00
$yellow: #ffc548;
$yellow-post-it: #E5BA6A;
$pink: #e48873;
$pink-scotch: #D09587;
$blue-post-it: #70ACB0;
$blue-light: #5ebcc2;
$grey-purple-scotch: #B3A8B4;
$grey-purple: #b5a4b8;
$white-grey: #efefef;

$accent-color: $purple;
$primary-color: $beige;

$dark-background-color:#5c2539;
$background-color: #845369;

$dark-title: #d9738b;

$menu-color-1: darken(#6f6fa4, 10%);
$menu-color-2: darken(#d06a7c, 15%);
$menu-color-3: darken(#7a92bf, 10%);
$menu-color-4: darken(#d4826b, 15%);
$menu-color-5: darken(#9887b2, 10);

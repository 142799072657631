@import 'styles/_colors';
@import 'styles/_fonts';

$card-title: $accent-color;
$card-title-weight: 400;

body {
  background-color: $light-grey;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

nav {
  background-color: transparent;
}

a {
  color: $purple;
}

.card .card-title {
  color: $card-title;
  font-weight: $card-title-weight;
  font-family: $blogger;
  font-size: 1.8em;
}
.col.no-padding {
  padding: 0;
}

.card.no-margin {
  margin: 0;
}

.no-margin-bottom {
  margin-bottom: 0;
}

@import '../../../styles/_colors';
@import '../../../styles/_fonts';

.sessions {
  padding-top: 1rem;
}

.sessions > div > div.card.card-content {
  padding: 20px;
  display: grid;
}
.sessions > div > div {
  font-family: $product-sans;
  font-size: 1.1em;
  vertical-align: middle;
  //margin: 0 auto;
}

@media screen and (min-width: 600px) {
  .sessions > div > div {
    min-height: 150px !important;
  }
}

.sessions > div:nth-child(6n+1) > div {
  background-color: lighten($grey-purple-scotch, 15%);
}

.sessions > div:nth-child(6n+2) > div {
  background-color: lighten($pink-scotch, 15%);
}

.sessions > div:nth-child(6n+3) > div{
  background-color: lighten($yellow-post-it, 10%);
}

.sessions > div:nth-child(6n+4) > div {
  background-color: lighten($green, 10%);
}

.sessions > div:nth-child(6n+5) > div {
  background-color: lighten($blue-post-it, 15%);
}

.sessions > div:nth-child(6n) > div{
  background-color: lighten($beige, 5%);
}

@import "../../styles/_colors";

$icon-color: $yellow-post-it;

.contact-card-content > p > a {
  font-weight: 500;
  line-height: 2.5;
}

.card.horizontal > .card-image.card-image-contact {
  max-width: 48em;
}

.contact-social {
  justify-content: flex-start;

  & > .social--item > .social--item--img {
    height: 4rem;
    padding: 0 1rem;
  }
}

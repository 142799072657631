.social--container {
  display: flex;
  justify-content: center;
}

.social--item {

}

.social--item--img {
  height: 2.2rem;
  margin: 0 0.5rem;
}

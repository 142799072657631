@import "../../styles/_colors.scss";
@import "../../styles/_fonts.scss";

$font-menu: $roboto;

$app-bar-color: $background-color;

$desktop-link-color: $beige-light;
$desktop-link-active-color: $white;
$desktop-link-hover-color: $desktop-link-active-color;
$desktop-link-text-weight: 500;

$drawer-background: $white;
$burger-icon-color: $desktop-link-color;

$logo-color: $white;
$logo-weight: 500;

#root > div > div > nav {
  background-color: $app-bar-color;
}

#root > div > div > div > ul > li > nav {
  background-color: $app-bar-color;
}

#root > div > div > div > ul {
  background-color: $drawer-background;
}


nav .nav-wrapper > .sidenav-trigger {
  color: $burger-icon-color;
}

nav .nav-wrapper > .brand-logo,
nav > .brand-logo {
  color: $logo-color;
}

nav .nav-wrapper > ul#nav-mobile > li > a:hover {
  //background-color: $primary-color;
  color: $desktop-link-hover-color;
  //font-weight: 600;
}

nav .nav-wrapper > ul#nav-mobile > li > a {
  color: $desktop-link-color;
  font-weight: $desktop-link-text-weight;
  font-family: $font-menu;
  border-bottom: 2px solid transparent;
  border-top: 4px solid transparent;
  margin: -6px 0 -6px 0;
}


nav .nav-wrapper > ul#nav-mobile > li:nth-child(1n) .active {
    background-color: $menu-color-1;
}

nav .nav-wrapper > ul#nav-mobile > li:nth-child(2n) .active {
    background-color: $menu-color-2;
}

nav .nav-wrapper > ul#nav-mobile > li:nth-child(3n) .active {
    background-color: $menu-color-3;
}

nav .nav-wrapper > ul#nav-mobile > li:nth-child(4n) .active {
    background-color: $menu-color-4;
}

nav .nav-wrapper > ul#nav-mobile > li:nth-child(5n) .active {
    background-color: $menu-color-5;
}

nav .nav-wrapper > ul#nav-mobile > li > a.active {
  // border-radius: 10px;
  // border-bottom-color: $purple;
  //background-color: $white;
  font-weight: 600;
  font-size: 1rem;
  color: $desktop-link-active-color;
  //border-bottom: 2px solid $desktop-link-active-color;
}

@media only screen and (min-width: 992px) {

  nav > div > a.brand-logo {
    padding-left: 20px;
  }
}

.sidenav-trigger.buttonLink:focus {
  background-color: $primary-color;
}

.brand-logo > .logo {
  font-family: $blogger;
  font-weight: $logo-weight;
}

.brand-logo {
  font-family: $product-sans;
  font-size: 2.1rem;
}

.buttonLink {
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.buttonLink:hover, .ButtonLink:focus {
  text-decoration: none;
}

@media (min-width: 1300px) {
  .hide-on-desktop {
    display: none;
  }
}

@media (max-width: 1300px) {
  .hide-on-mobile-and-tablet {
    display: none;
  }
}


.reseau-links {
  padding-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.reseau-link {
  align-items: center;

  & > img {
    padding: 1.2rem;
    max-width: 16rem;
    max-height: 14rem;
  }
}
